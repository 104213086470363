import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { baseUrl } from '../../../config'

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.css']
})
export class OtpVerifyComponent implements OnInit {

  number!: string;
  dataForm!: FormGroup;
  showError: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.number = params['number']
    });
    this.dataForm = new FormGroup({
      otp: new FormControl('', Validators.required)
    })
  }

  verifyOtp(): void {
    if (this.dataForm.invalid || this.dataForm.value.otp.length !== 6) {
      this.showError = true;
      return;
    };

    if (this.showError) {
      this.showError = false;
    }

    var requestUrl = `${baseUrl}/verify?phone=${this.number}&code=${this.dataForm.value.otp}`;

    fetch(requestUrl).then(responseBody => responseBody.text()).then((response) => {
      if (JSON.parse(response).error === "Invalid or Expired Code") {
        alert(JSON.parse(response).error);
      } else {
        sessionStorage.setItem(`prodiledata-${this.number}`, response);
        this.router.navigate(['/profile'], {
          queryParams: {
            number: this.number
          }
        });
      }
    }).catch((error) => {
      console.error('Error:', error.message);
      alert(`Error, Can't verify OTP right now`);
    });
    
  }

}
