<div class="container" *ngIf="profileData && number">
    <div class="main-body">
        <div class="row">
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img [src]="profileData?.pfpUrl" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
                            <div class="mt-3">
                                <h4>{{ profileData.name}}</h4>
                                <p class="text-secondary mb-1">Bio</p>
                                <p class="text-muted font-size-sm"> {{ profileData.bio ? profileData.bio : "N/A" }}</p>
                                <p class="text-muted font-size-sm">Phone No. {{ number }}</p>
                            </div>
                        </div>
                        <hr>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Level : <span class="text-secondary">{{ profileData.level }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Banned : <span class="text-secondary">{{ profileData.banned }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Bank : <span class="text-secondary">{{ profileData.bank }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Wallet : <span class="text-secondary">{{ profileData.wallet }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Tags : <span class="text-secondary">{{ profileData.tag }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Mods : <span class="text-secondary">{{ profileData.mod }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Premium : <span class="text-secondary">{{ profileData.premium }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Ignore : <span class="text-secondary">{{ profileData.ignore }}</span></span>
                            </li>
                            <li class="list-group-item d-flex align-items-center flex-wrap">
                                <span class="mb-0">Exp. : <span class="text-secondary">{{ profileData.exp }}</span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="card">
                    <div class="card-body">
                        <h5 class="d-flex align-items-center">Deck</h5>
                        <p-carousel [value]="profileData?.deck" [numVisible]="3" [numScroll]="3" [circular]="false" [responsiveOptions]="responsiveOptions">
                            <ng-template let-data pTemplate="item">
                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                    <div class="mb-3">
                                        <img [src]="data?.image" [alt]="data?.name" class="w-6 shadow-2" />
                                    </div>
                                    <div>
                                        <h4 class="mb-1">{{ data.name }}</h4>
                                        <h6 class="mt-0 mb-3">{{ 'Tier ' + data.tier }}</h6>
                                        <p>{{ data?.description }}</p>
                                    </div>
                                </div>
                            </ng-template>
                        </p-carousel>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="d-flex align-items-center">Party</h5>
                        <p-carousel [value]="profileData?.party" [numVisible]="3" [numScroll]="3" [circular]="false" [responsiveOptions]="responsiveOptions">
                            <ng-template let-data pTemplate="item">
                                <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                    <div class="mb-3">
                                        <img [src]="data?.image" [alt]="data?.name" class="w-6 shadow-2" />
                                    </div>
                                    <div>
                                        <h4 class="mb-1">{{ data.name }}</h4>
                                        <h6 class="mt-0 mb-3">Attack : {{ data.attack }}</h6>
                                        <h6 class="mt-0 mb-3">Defence : {{ data.defence }}</h6>
                                        <h6 class="mt-0 mb-3">Exp : {{ data.exp }}</h6>
                                        <h6 class="mt-0 mb-3">Level : {{ data.level }}</h6>
                                        <h6 class="mt-0 mb-3">Speed : {{ data.speed }}</h6>
                                        <h6 class="mt-0 mb-3">Gender : {{ data.female ? "Female" : "Male" }}</h6>
                                    </div>
                                </div>
                            </ng-template>
                        </p-carousel>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="d-flex align-items-center mb-3">Inventory</h5>
                                <div *ngFor="let data of profileData.Inventory">
                                    <p>{{data.item}} - Usage Left - {{ data.usageLeft }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="center-box" style="background-color: #fff;" *ngIf="!profileData || !number">
    <h1>No data found</h1>
</div>