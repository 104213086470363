<div class="container">
    <form id="contact" [formGroup]="dataForm">
        <h1 style="font-weight: 600; font-size: xx-large;" class="d-flex justify-content-center m-0">WhatsApp Verification</h1>
        <p class="description" class="d-flex justify-content-center">Please enter the verification code below:</p>
        <fieldset class="m-0">
            <label for="verification-code">Verification Code:</label>
            <input class="m-0" type="tel" id="verification-code" formControlName="otp" name="verification-code" placeholder="Enter verification code" maxlength="6">
            <p *ngIf="showError" style="color: red;">Please fill a valid OTP with 6 digits.</p>
        </fieldset>
        <fieldset>
            <button name="submit" type="submit" (click)="verifyOtp()" id="contact-submit">Continue</button>
        </fieldset>
    </form>
</div>