<div class="container">
    <form id="contact">
        <div style="display: flex; justify-content: center; text-align: center; flex-direction: column;">
            <h1>
                <i class="fa-solid fa-phone fa-beat"></i>
            </h1>
            <h1 class="title">Verify You are real!</h1>
            <p class="description">Enter your phone number to verify it</p>
        </div>
        <fieldset style="display: flex; flex-direction: column;">
            <label for="phone">Phone:</label>
            <input #input type="tel" id="phone" name="phone" />
        </fieldset>
        <fieldset>
            <button name="submit" type="submit" id="contact-submit" class="verify-button" disabled>Submit</button>
        </fieldset>
        <p class="error-message" style="color: red; display: none">
            Please, enter valid phone number.
        </p>
    </form>
</div>