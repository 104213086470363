import { Component, AfterContentInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import * as intlTelInput from 'intl-tel-input';
import { baseUrl } from '../../../config'

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent implements AfterViewInit {

  iti!: any;

  @ViewChild('input', { static: true}) input: any;

  constructor(
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    var continueButton: any = document.querySelector('.verify-button');
    var errorMessage: any = document.querySelector('.error-message');
    if (this.input.nativeElement) {
      this.iti = intlTelInput(this.input.nativeElement, {
        initialCountry: 'pk',
        preferredCountries: ['in', 'ng', 'pk'],
        separateDialCode: true,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js',
      });
    }

    this.input.nativeElement.addEventListener('input', () => {
      const phoneNumber = this.input.nativeElement.value;
      const countryCode = this.iti.getSelectedCountryData().iso2;
      const isNigeria = countryCode === 'ng';
      const digitsOnly = phoneNumber.replace(/\D/g, '');
      const isTenDigits = digitsOnly.length === 10;

      // Custom validation for Nigerian numbers
      if (isNigeria) {
        if (isTenDigits) {
          continueButton.disabled = false;
          errorMessage.style.display = 'none';
        } else {
          continueButton.disabled = true;
          errorMessage.style.display = 'block';
        }
      } else {
        const isValid = this.iti.isValidNumber();
        if (isValid) {
          continueButton.disabled = false;
          errorMessage.style.display = 'none';
        } else {
          continueButton.disabled = true;
          errorMessage.style.display = 'block';
        }
      }
    });

    var form: any = document.querySelector('form');
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      var phoneNumber = this.iti.getNumber(); // Remove non-digits, write area code
      const newphoneno = phoneNumber.replace(/\+/g, '');
      var requestUrl = `${baseUrl}/request?phone=${newphoneno}`;
      
      fetch(requestUrl)
        .then((response) => response.text())
        .then((responseBody) => {
          if (responseBody === 'Open Your WhatsApp!') {
            this.router.navigate(['/otp-verify'], {
              queryParams: {
                number: newphoneno
              }
            });
          } else {
            alert(JSON.parse(responseBody).error ?? JSON.parse(responseBody).cooldown);
          }
        })
        .catch((error) => {
          console.error('Error:', error.message);
          alert('Error sending OTP');
        });
    });
  }

}
