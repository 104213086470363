import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'phone-number'
  },
  {
    path: 'phone-number',
    component: PhoneNumberComponent
  },
  {
    path: 'otp-verify',
    component: OtpVerifyComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'phone-number'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
